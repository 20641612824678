import React from 'react';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import MicIcon from '@mui/icons-material/Mic';
import CloseIcon from '@mui/icons-material/Close';

import CustomButton from 'components/CustomButton';

import { askPermission, checkPermission } from 'utils/permissions';
import { Button } from '@mui/material';
import { detectMobileDevice, openSystemPrivacySettings, openSystemSoundSettings, subscribeToAudioLevel } from 'utils';
import TroubleShoot from 'screens/TroubleShoot';

const useStyles = makeStyles((theme) => ({
    micSection: {
      boxShadow: "0px -2px 8px 0px #00000029",
      padding: '20px',
    },
    micContent: {
      display: 'flex',
      gap: '4px',
      justifyContent: 'space-between',
      alignItems: 'center',
      border: '1px solid #9DC2F2',
      padding: '7px 8px'
    },
    micDanger: {
      fontSize: '10px',
      fontWeight: 600,
      color: '#C51407',
    },
    micSuccess: {
      fontSize: '12px',
      fontWeight: 600,
      color: '#00664A',
    },
    micBox: {
      display: 'flex',
      flexDirection: 'column',
      gap: '4px',
    },
    mic: {
        display: 'flex',
        alignItems: 'center',
        gap: '4px',
    },
    systemSetting: {
      position: 'absolute',
      height: '100%',
      width: '100%',
      backgroundColor: '#fff',
      top: 0,
      left: 0,
      zIndex: 5,
    },
    closeIcon: {
      position: 'absolute',
      top: 5,
      right: 5,
      zIndex: 6,
      cursor: 'pointer'
    },
    suggestionBox: {
      position: 'absolute',
      bottom: 10,
      right: 10,
      zIndex: 6,
    },
    systemSettingContent: {
      height: '100%',
      width: '100%',
      display: 'flex', flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '10px',
      gap: '5px',
    },
    subTitle1: {
      fontSize: '12px',
      color: '#4E5661',
    },
    troubleShootBtn: {
      textTransform: 'none',
      padding: 0,
      fontSize: '11px',
      textDecoration: 'underline',
    }
}));

export const MicPermissions = {
  GRANTED: 'granted',
  PROMPT: 'prompt',
  DENIED: 'denied',
}

export function SystemSettingErrorState({ muted = false, onClose=()=>{}}){
  const classes = useStyles();

  return(
    <Box className={classes.systemSetting}>
      <Box className={classes.closeIcon} onClick={onClose}>
        <CloseIcon style={{ fontSize: '20px'}} />
      </Box>
      <Box className={classes.systemSettingContent}>
        <img 
          src='https://assets.languify.in/images/microphone.svg' alt='mic' 
          style={{ height: '100px', width: '100px', marginBottom: '30px'}}
        />
        <Box width='100%'>
          <Typography fontSize='20px' fontWeight={600}>
            Follow these steps
          </Typography>

          {
              <Box>
              {
                muted ?
                <Typography className={classes.subTitle1} component="ul" sx={{ pl: 2, mb: 2 }}>
                  <li>Unmute your microphone.</li>
                  <li>Check and if required adjust its volume level.</li>
                  <li>Refresh the page after updating the settings.</li>
                </Typography>
                :
                <Typography className={classes.subTitle1} component="ul" sx={{ pl: 2, mb: 2 }}>
                  <li>Click the above icon in the address bar next to the website's URL.</li>
                  <li>Under 'Permissions' you will get instructions to enable the system microphone.</li>
                  <li>Refresh the page after updating the settings.</li>
                </Typography>
              }
                <Box style={{ display: 'flex', justifyContent: 'center'}}>
                  <Button variant='outlined' style={{ marginTop: '10px', textTransform: 'none'}} onClick={openSystemSoundSettings}>
                    Open System Settings
                  </Button>
                </Box>
              </Box>
          }
        </Box>
      </Box>
    </Box>
  )
}

export function BrowserErrorState({ onClose=()=>{}}){
  const classes = useStyles();

  return(
    <Box className={classes.systemSetting}>
      <Box className={classes.closeIcon} onClick={onClose}>
        <CloseIcon style={{ fontSize: '20px'}} />
      </Box>
      <Box className={classes.systemSettingContent}>
        <Box
          style={{
            width: '100%', display: 'flex', justifyContent: 'flex-end',
            marginTop: '-50px', marginRight: '-50px'
          }}
        >
          <img 
            src='https://assets.languify.in/images/browser-setting.svg' alt='mic' 
            style={{ width: '250px', marginBottom: '20px'}}
          />
        </Box>
        <Typography fontSize='20px' fontWeight={600}>
          How to enable your browser microphone permissions
        </Typography>

        <Box>
          <Typography className={classes.subTitle1} component="ul" sx={{ pl: 2, mb: 2 }}>
            <li>Click the above icon in the address bar next to the website's URL.</li>
            <li>Select "Site settings" from the dropdown menu.</li>
            <li>Under 'Permissions,' locate 'Microphone' & and change it to allow.</li>
            <li>Now “Refresh” the page to apply changes.</li>
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export const MicCalibration = ({ next=()=>{} }) => {
  const classes = useStyles();
  const [error, setError] = React.useState("");
  const [audioLevel, setAudioLevel] = React.useState(0);
  const unsubscribeRef = React.useRef();
  const devicesRef = React.useRef({current: []});
  const [devices, setDevices] = React.useState([]);
  const [permission, setPermission] = React.useState(null);
  const [openSystemSettings, setOpenSystemSettings] = React.useState(false);
  const [openBrowserSettings, setOpenBrowserSettings] = React.useState(false);
  const [openTroubleShoot, setOpenTroubleShoot] = React.useState(false);
  const [micWorking, setMicWorking] = React.useState(false);

  const [systemBlocked, setSystemBlocked] = React.useState(false);
  const [browserBlocked, setBrowserBlocked] = React.useState(false);
  const [systemDenied, setSystemDenied] = React.useState(false);
  const [micMuted, setMicMuted] = React.useState(false);
  const isMobile = detectMobileDevice();

  const fetchDevices = async () => {
    try {
      const allDevices = await navigator.mediaDevices.enumerateDevices();
      const audioInputs = allDevices.filter((device) => device.kind === 'audioinput');
      setDevices(audioInputs);
      devicesRef.current = audioInputs;
    } catch (error) {
      console.error('Error fetching devices:', error);
    }
  };

  const checkPermissions = async () => {
    try {
      const permissionStatus = await navigator.permissions.query({ name: 'microphone' });
      setPermission(permissionStatus.state);

      permissionStatus.onchange = () => {
        setPermission(permissionStatus.state);
      };

      const isPermitted = await askPermission({ video: false, audio: true });
      
      if (isPermitted){
        subscribe();
      }

    } catch (error) {
      console.error('Error checking permissions:', error);
    }
  };

  const requestPermission = async () => {
      await checkPermissions();
      const isMicAllowed = await checkPermission("microphone");
      fetchDevices();

      if (isMicAllowed) {
          subscribe();
          return;
      }

      const isPermitted = await askPermission({ video: false, audio: true });
      
      if (isPermitted){
        subscribe();
      }
  };

  const handleClick = async () => {
      if(permission === MicPermissions.GRANTED){
        next();
      }

      // eslint-disable-next-line no-undef
      else if (typeof AndroidApp !== 'undefined') {
          console.log('DEBUG: Found AndroidApp');
          const callbackName = 'onMicrophonePermissionResult';
          // eslint-disable-next-line no-undef            
          AndroidApp?.requestMicrophonePermission(callbackName);
          console.log('DEBUG: requestMicrophonePermission called');
          return;
      } else {
          requestPermission();
      }
  };

  const subscribe = React.useCallback(async () => {
    try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });

        const track = stream.getAudioTracks()[0];

        const handleMute = () => {
          setMicWorking(false);
          setMicMuted(true);
          setError("Microphone is muted by the system settings.");
        };
        
        const handleUnmute = () => {
          setMicWorking(true);
          setMicMuted(false);
          setError("");
        };

        if (track.muted) {
          handleMute();
        } else {
          handleUnmute();
        }

        track.onmute = handleMute;
        track.onunmute = handleUnmute;

        unsubscribeRef.current = subscribeToAudioLevel(stream, (level) => {
          setAudioLevel(level);
          if(level) setMicWorking(true);
        });
    } catch (error) {
        console.error('Error in subscribing: ', error);
        if(devicesRef.current.length){
          setError("In Windows Settings, open Privacy & Security and enable microphone access for your browser. Afterwords, refresh the page.");
          setMicWorking(false);
          setSystemDenied(true);
        }
    }
  }, []);

  const handleEnableMic = async () => {
    if(systemBlocked){
      if(isMobile) setOpenSystemSettings(true);
      else openSystemSoundSettings();
    }
    if(systemDenied){
      openSystemPrivacySettings();
    }
    if(micMuted){
      setOpenSystemSettings(true);
    }
    if(devices.length && (permission === MicPermissions.DENIED || permission === MicPermissions.PROMPT)){
      setOpenBrowserSettings(true);
    }else{
      subscribe();
    }
  };

  React.useEffect(() => {
    const init = async () => {
      await fetchDevices();
      await checkPermissions();
    };
  
    init();
  
    const handleDeviceChange = async () => {
      console.log('DEBUG: Device change detected');
      await fetchDevices();
    };
  
    navigator.mediaDevices.addEventListener("devicechange", handleDeviceChange);
  
    return () => {
      navigator.mediaDevices.removeEventListener("devicechange", handleDeviceChange);
    };
  }, []);

  React.useEffect(() => {
    if(permission){
      // setError("");
      if(!devices.length){
        setSystemBlocked(true);
        setMicWorking(false);
        setError("No microphone detected. Please connect one, If exists make it enabled in the system settings.");
      }
      if(devices.length && (permission !== MicPermissions.GRANTED)){
        setMicWorking(false);
        setBrowserBlocked(true);
        setError("Kindly enable your microphone in browser settings.");
      }
      if(permission===MicPermissions.GRANTED){
        subscribe();
      }
    }
  }, [devices, permission]);

  return (
    <Box className={classes.micSection}>
      <Box className={classes.micContent}>
        <Box className={classes.micBox}>
        {
          error ?
          <Typography className={classes.micDanger}>
            {error}
          </Typography>
          : 
          <Typography className={classes.micSuccess}>
          {
            micWorking ? 
              "Microphone working. You can attempt now..." :
              "Say something to check your microphone..."
          }
          </Typography>
        }

        <Box className={classes.mic}>
            <MicIcon style={{ fontSize: '18px' }} />
            <Box sx={{ width: '120px' }}>
                <LinearProgress
                    variant="determinate" value={audioLevel}
                    style={{ borderRadius: '10px' }}
                />
            </Box>
        </Box>

        </Box>
        {
          (!devices.length || systemDenied || permission !== MicPermissions.GRANTED || micMuted) ?
            <CustomButton
                size="small"
                style={{ minWidth: 'fit-content'}}
                onClick={handleEnableMic}
            >
            {
              browserBlocked || micMuted ? "Enable Mic" : 'Open Settings'
            }
            </CustomButton>
          :
            <CustomButton
                size="small"
                style={{ minWidth: 'fit-content'}}
                onClick={handleClick}
                disabled={ !devices.length || !micWorking}
            >
                Proceed
            </CustomButton>
        }
      </Box>
      {
        micWorking &&
        <Box display='flex' alignItems='center' gap={1} mt='2px'>
          <Typography fontSize='10px'>
            Having microphone issues? 
          </Typography>
          <Button className={classes.troubleShootBtn} onClick={() => setOpenTroubleShoot(true)}>
            Troubleshoot here
          </Button>
        </Box>
      }
      {
        openSystemSettings &&
        <SystemSettingErrorState
          muted={micMuted}
          onClose={() => setOpenSystemSettings(false)}
        />
      }
      {
        openBrowserSettings &&
        <BrowserErrorState
          onClose={() => setOpenBrowserSettings(false)}
        />
      }
      {
        openTroubleShoot &&
        <TroubleShoot
          onClose={() => setOpenTroubleShoot(false)}
          openBrowserSettings={openBrowserSettings}
          setOpenBrowserSettings={setOpenBrowserSettings}
        />
      }
    </Box>
  )
}