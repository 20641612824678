import { makeStyles } from '@mui/styles';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardVoiceOutlinedIcon from '@mui/icons-material/KeyboardVoiceOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import MicIcon from '@mui/icons-material/Mic';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EastIcon from '@mui/icons-material/East';
import { 
  Accordion, AccordionDetails, AccordionSummary, Box, 
  Button, Divider, LinearProgress, TextField, Typography 
} from '@mui/material'

import Tabs from 'components/Tabs';
import Chip from 'components/Chip';
import { 
  askPermission, calculateStringMatchPercentage, 
  checkPermission, openSystemPrivacySettings, openSystemSoundSettings, 
  subscribeToAudioLevel 
} from 'utils';
import { generateTranscript } from 'services';
import { 
  BrowserErrorState, MicPermissions, 
  SystemSettingErrorState 
} from 'components/MicCalibration';
import { 
  troubleshootMicError, troubleshootSuggestions, 
  troubleshootTranscriptError 
} from 'components/constants';

const useStyles = makeStyles((theme) => ({
  textFieldRoot: {
    margin: "0",
    width: '100%',
    borderRadius: "4px",
    fontSize: 12,
    fontWeight: "normal",
    "& .MuiInputLabel-root": {
      fontSize: "12px !important",
      fontWeight: "normal !important",
    },
    "& .MuiFilledInput-root": {
      fontSize: 12,
      fontWeight: "normal",
      borderRadius: "4px",
      backgroundColor: "white",
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.15)",
      '&>*': {
        padding: 10
      }
    },
    '& .MuiOutlinedInput-root': {
      backgroundColor: "#E4E3E8",
      borderRadius: "4px",
      fontSize: 12,
      fontWeight: "normal",
      '& fieldset': {
        borderRadius: "4px",
        borderColor: "transparent",
        fontWeight: "normal",
        fontStyle: "normal",
        fontFamily: "'Montserrat', sans-serif",
        fontSize: 12,
      },
      '&:hover fieldset': {
        borderColor: '#02569D',
        borderRadius: "4px",
        fontSize: 12,
        fontWeight: "normal",
      },
      '&.Mui-focused fieldset': {
        borderColor: '#02569D',
        borderRadius: "4px",
        fontSize: 12,
        fontWeight: "normal",
      },
    },
  },
  root: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    backgroundColor: '#fff',
    top: 0,
    left: 0,
    zIndex: 1,
    padding: '10px',
    overflow: 'auto',
    "&::-webkit-scrollbar": { width: '5px', height: 0 },
    "&::-webkit-scrollbar-thumb": { width: '5px', height: 0, },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  checklist: {
    marginTop: '8px',
    display: 'flex',
    gap: '8px'
  },
  micTest: {
    marginTop: '15px',
    display: 'flex',
    flexDirection: 'column',
    gap: '4px'
  },
  suggestionBox: {
    marginTop: '20px',
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
    backgroundColor: '#F5F9FF',
    padding: '8px',
    borderRadius: '8px'
  },
  suggestionItems: {
    fontSize: '12px',
    color: '#4E5661',
  },
  micRecordComp: {
    marginTop: '15px',
    display: 'flex',
    flexDirection: 'column',
    gap: '4px'
  },
  micRecord: {
    display: 'flex',
    gap: '4px'
  },
  mic: {
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
  },
  switchMicBtn: {
    textTransform: 'none',
    padding: 0,
    fontSize: '11px',
    textDecoration: 'underline',
  },
  proceedBtn: {
    marginTop: '20px',
    padding: '8px',
    display: 'flex',
    justifyContent: 'space-between',
    gap: '4px',
    borderRadius: '8px',
    backgroundColor: '#003E8C',
    alignItems: 'center'
  }
}));

const sx = {
  danger: {fontSize: '14px', color: '#C51407'},
  success: {fontSize: '14px', color: '#00664A'}
}


function Questions({ openBrowserSettings, setOpenBrowserSettings }) {
  const [expanded, setExpanded] = React.useState(0);
  const [openSystemSettings, setOpenSystemSettings] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
  };

  const QuestionAnswers = [
    {
      question: "How to take the microphone test?",
      answer: "Inside the Troubleshoot >> Microphone section, first check the microphone in use, if it is correct, click on the 'Record' button and repeat the mentioned sentence clearly. Click on 'Stop' once you complete speaking. Listen to your recording and check the microphone and transcript status. If both are green, you are ready to take the attempt.",
    },
    {
      question: "What to do if my microphone is not working?",
      answer: <>
          <Typography fontSize='12px' component="ul" sx={{ pl: 2, mb: 2 }}>
            <li>Check that your external microphone is properly connected & in appropriate proximity.</li>
            <li>Don’t use faulty or damaged microphone ports.</li>
            <li>Verify that the correct microphone is selected.</li>
            <li>The microphone itself may be damaged or non-functional.</li>
            <li>Another application might be using or blocking the microphone.</li>
            <li>Some antivirus programs may block microphone access for security reasons.</li>
            <li>Corporate system sometimes block access for security reasons.</li>
          </Typography>
      </>,
    },
    {
      question: "How to resolve transcript issues?",
      answer: <>
          <Typography fontSize='12px' component="ul" sx={{ pl: 2, mb: 2 }}>
            <li>Make sure microphone is in appropriate proximity.</li>
            <li>Low-quality or faulty microphones may not capture clear audio. Use good quality microphone.</li>
            <li>Being too far or too close to the microphone can affect input quality. Maintain an optimal distance from the mic.</li>
            <li>Ambient sounds (e.g., fans, traffic, conversations) can interfere with audio clarity.</li>
            <li>Try to avoid mumbled, fast, or unclear speech.</li>
            <li>Other running applications may interfere with the mic input, close them & try again.</li>
            <li>Incorrect microphone sensitivity of microphone in computer (too low or too high) can distort the input
              . Check here:<span 
                style={{ cursor: 'pointer', color: '#1961BF', textDecoration: 'underline', fontWeight: 600 }}
                onClick={openSystemSoundSettings}
              > Open system sound settings</span>
            </li>
          </Typography>
      </>,
    },
    {
      question: "How to enable microphone in browser?",
      answer: <>Click on <span onClick={()=>setOpenBrowserSettings(true)} style={{ cursor: 'pointer', color: '#1961BF', textDecoration: 'underline', fontWeight: 600 }}>Enable microphone in browser</span> & follow the steps.</>,
    },
    {
      question: "How to enable microphone access for the browser in System?",
      answer: <>In System Settings, open <span onClick={()=>openSystemSoundSettings()} style={{ cursor: 'pointer', color: '#1961BF', textDecoration: 'underline', fontWeight: 600 }}>Open system sound settings.</span> and enable microphone access for your browser.</>,
    },
    {
      question: "How to enable microphone that is blocked by system settings in computer?",
      answer: <>Click on <span onClick={()=>openSystemPrivacySettings()} style={{ cursor: 'pointer', color: '#1961BF', textDecoration: 'underline', fontWeight: 600 }}>Privacy & Security.</span> Unmute your microphone & adjust its level.</>,
    },
    {
      question: "How to change microphone",
      answer: <>
          <Typography fontSize='12px'>
            To switch your microphone in <b>Chrome, Brave, and Edge.</b> Follow these steps;
          </Typography>
          <Typography fontSize='12px' component="ol" sx={{ pl: 2, mb: 2 }}>
            <li>Click on the     or      in the browser navbar top-right corner.</li>
            <li>Select <b>Settings</b> from the dropdown menu.</li>
            <li>In the settings menu, click on <b>Privacy and Security.</b></li>
            <li>Select <b>Site Settings.</b></li>
            <li>Scroll to the Permissions section.</li>
            <li>Click on <b>Microphone.</b></li>
            <li>Use the dropdown menu to select your preferred microphone.</li>
          </Typography>
          <Divider/>
          <Typography fontSize='12px' mt={2}>
            In case of Mozilla Firefox; 
          </Typography>
          <Typography fontSize='12px' component="ul" sx={{ pl: 2, mb: 2 }}>
            <li>you can use another microphone.</li>
            <li>Try to use another browser from above list.</li>
            <li>If issue persists try to attempt on another device.</li>
          </Typography>
      </>,
    },
  ];

  return (
      <Box mt={2}>
          <Typography fontSize={14} fontWeight={600}>
              Frequently Asked Questions
          </Typography>
          <Box style={{display: 'flex', flexDirection: 'column', gap: '8px', marginTop: '8px'}}>
            {QuestionAnswers.map(({ question, answer }, i) => (
              <Accordion
                  key={i}
                  elevation={0}
                  expanded={expanded === i} onChange={handleChange(i)}
                  style={{ border: '2px solid #BFCAD9'}}
              >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} mt={1}>
                      <Typography fontSize='13px' fontWeight={600}>{i+1}. {question}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                      <Typography fontSize='12px'>{answer}</Typography>
                  </AccordionDetails>
              </Accordion>
            ))}
          </Box>

        {
          openSystemSettings &&
          <SystemSettingErrorState
            onClose={() => setOpenSystemSettings(false)}
          />
        }
        {
          openBrowserSettings &&
          <BrowserErrorState
            onClose={() => setOpenBrowserSettings(false)}
          />
        }
      </Box>
  );
}

const Microphone = ({ onClose }) => {
  const classes = useStyles();
  const [audioLevel, setAudioLevel] = React.useState(0);
  const unsubscribeRef = React.useRef();
  const [currentDevice, setCurrentDevice] = React.useState("");
  const [devices, setDevices] = React.useState([]);
  const [permission, setPermission] = React.useState(null);
  const [recording, setRecording] = React.useState();
  const audioElement = React.useRef(null);
  const mediaRecorder = React.useRef(null);
  const [transcript, setTranscript] = React.useState("");
  const [micWorking, setMicWorking] = React.useState(false);
  const [transcriptMatch, setTranscriptMatch] = React.useState(false);
  const [analysing, setAnalysing] = React.useState(false);
  const [analysed, setAnalysed] = React.useState(false);
  const [openSystemSettings, setOpenSystemSettings] = React.useState(false);
  const [openBrowserSettings, setOpenBrowserSettings] = React.useState(false);

  const subscribe = React.useCallback(async () => {
    try {
      navigator.mediaDevices.getUserMedia({ audio: true })
        .then(function (result) {
          unsubscribeRef.current = result;

            subscribeToAudioLevel(unsubscribeRef.current, setAudioLevel);

        }).catch(console.error);

      return () => unsubscribeRef.current?.getTracks()?.forEach(t => t.stop());
    }catch (error) {
      console.error(error);
    }
  }, []);

  const fetchDevices = async () => {
    try {
      const allDevices = await navigator.mediaDevices.enumerateDevices();
      const audioInputs = allDevices.filter((device) => device.kind === 'audioinput');
      setDevices(audioInputs);

      if (audioInputs.length > 0) {
        setCurrentDevice(audioInputs[0].label);
      }
    } catch (error) {
      console.error('Error fetching devices:', error);
    }
  };

  const checkPermissions = async () => {
    try {
      const permissionStatus = await navigator.permissions.query({ name: 'microphone' });
      setPermission(permissionStatus.state);

      const handlePermissionChange = () => {
        setPermission(permissionStatus.state);
      };
      permissionStatus.onchange = handlePermissionChange;

      return () => {
        permissionStatus.onchange = null;
      };
    } catch (error) {
      console.error('Error checking permissions:', error);
    }
  };

  const requestPermission = async () => {
    await checkPermissions();
    const isMicAllowed = await checkPermission("microphone");
    fetchDevices();

    if (isMicAllowed) {
        subscribe();
        return;
    }

    const isPermitted = await askPermission({ video: false, audio: true });
    
    if (isPermitted){
      await checkPermissions();
      subscribe();
    }
  }

  React.useEffect(() => {
    (async () => {
      await requestPermission();
    })();
  },[]);

  React.useEffect(()=>{
    const match = calculateStringMatchPercentage(transcript, "Just making sure this mic is on the job not on vacation");
    setTranscriptMatch(match>70)
  },[transcript])

  const analyseAndGenerateTranscript = React.useCallback(async (audioBlob) => {
    console.log("Controls::handleSpeechSuccess->", audioBlob);
    setAnalysing(true);
  
    try {
      await new Promise((resolve, reject) => {
        generateTranscript({ audioBlob }, (e, data) => {
          if (e === 'analysed') {
            console.log("Analysed Data: ", data);
            setTranscript(data.result.transcript);
            setAnalysed(true);
            resolve();
          } else {
            reject(e);
          }
        });
      });
    } catch (error) {
      console.error("Error during analysis:", error);
    } finally {
      setAnalysing(false);
    }
  }, []);

  const handleClick = React.useCallback(() => {
    if (recording) {
        console.log("State: ", mediaRecorder.current.state);
        // stop if recording
        if (mediaRecorder.current.state === 'recording')
            mediaRecorder.current.stop();
            
    } else {
        // Pause if playing
        audioElement.current?.pause();
        // Start Recording
        mediaRecorder.current = new MediaRecorder(unsubscribeRef.current);

        const audioChunks = [];

        mediaRecorder.current.addEventListener("dataavailable", event => {
            audioChunks.push(event.data);
        });

        mediaRecorder.current.addEventListener("stop", () => {
            const audioBlob = new Blob(audioChunks, {type: 'audio/wav'});
            const audioUrl = URL.createObjectURL(audioBlob);
            audioElement.current = new Audio(audioUrl);
            analyseAndGenerateTranscript(audioBlob);
            audioElement.current.play();
        });

        mediaRecorder.current?.start();

    }
    setRecording(r => !r);
  }, [recording]);

  React.useEffect(() => {
      const init = async () => {
        await fetchDevices();
        await checkPermissions();
      };
    
      init();
    
      const handleDeviceChange = async () => {
        await fetchDevices();
      };
    
      navigator.mediaDevices.addEventListener("devicechange", handleDeviceChange);
    
      return () => {
        navigator.mediaDevices.removeEventListener("devicechange", handleDeviceChange);
      };
    }, []);

  React.useEffect(()=>{
    if(audioLevel){
      setMicWorking(true);
    }
  },[audioLevel])

  return(
    <Box mt={2}>
      <Box mb={2}>
        <Typography fontSize='16px' fontWeight={600}>Microphone checklist</Typography>
        <Box className={classes.checklist}>
          <Chip
            sx={permission !== MicPermissions.GRANTED && { cursor: 'pointer' }}
            onClick={()=>{ permission !== MicPermissions.GRANTED && setOpenBrowserSettings(true) }}
            content={'Browser permissions'}
            bgColor={ permission === MicPermissions.GRANTED ? '#DCFCE7' : '#FFE6E3'}
            color={ permission === MicPermissions.GRANTED ? '#00664A' : '#C51407'}
            startIcon={
              permission === MicPermissions.GRANTED ?
              <CheckCircleOutlinedIcon style={sx.success}/> :
              <ErrorOutlineOutlinedIcon style={sx.danger}/>
            }
          />
          <Chip
            sx={!devices.length && { cursor: 'pointer' }}
            content={'System permissions'}
            onClick={()=>{ !devices.length && setOpenSystemSettings(true) }}
            bgColor={ devices.length ? '#DCFCE7' : '#FFE6E3'}
            color={devices.length ? '#00664A' : '#C51407'}
            startIcon={
              devices.length ?
              <CheckCircleOutlinedIcon style={sx.success}/> :
              <ErrorOutlineOutlinedIcon style={sx.danger}/>
            }
          />
        </Box>
      </Box>
      
      <Divider style={{ borderColor: '#CCD4DE'}}/>

      <Box className={classes.micTest}>
        <Typography fontSize={'12px'} fontWeight={600}>
          Repeat & record the sentence to test your microphone
        </Typography>
        <Typography fontSize={'15px'} fontWeight={600} color={'#003E8C'} mt={1}>
          Just making sure this mic is on the job, not on vacation!
        </Typography>
      </Box>

      <Box className={classes.micRecordComp}>
        <Typography fontSize={'12px'} fontWeight={600}>Microphone in use</Typography>
        <Box className={classes.micRecord}>
          <TextField
            className={classes.textFieldRoot}
            disabled
            value={currentDevice}
            size="small"
          />
          <Button
              style={{ 
                minWidth: 'fit-content', textTransform: 'none', 
                padding: '0px 8px', 
                backgroundColor: (permission === MicPermissions.GRANTED && !recording && !analysing) && '#C51407' 
              }}
              size="small" variant={recording? 'outlined' : "contained"}
              onClick={handleClick}
              disabled={permission !== MicPermissions.GRANTED || analysing}
          >
            {recording ? "Stop" : analysing ? 'Analyzing' : "Record"}
          </Button>
        </Box>
        <Box className={classes.mic}>
          <MicIcon style={{ fontSize: '18px' }} />
          <Box sx={{ width: '120px' }}>
              <LinearProgress
                  variant="determinate" value={audioLevel}
                  style={{ borderRadius: '10px' }}
              />
          </Box>
          {/* <Button
            className={classes.switchMicBtn}
            onClick={ handleOpenSettings }
          >
            Switch microphone
          </Button> */}
        </Box>
      </Box>

      {
        analysed &&
        <Box mt={2}>
          <Box className={classes.checklist}>
            <Chip 
              content={'Microphone'}
              bgColor={ micWorking ? '#DCFCE7' : '#FFE6E3'}
              color={ micWorking ? '#00664A' : '#C51407'}
              startIcon={
                micWorking ?
                <CheckCircleOutlinedIcon style={sx.success}/> :
                <ErrorOutlineOutlinedIcon style={sx.danger}/>
              }
            />
            <Chip
              content={'Transcription'}
              bgColor={ transcriptMatch ? '#DCFCE7' : '#FFE6E3'}
              color={transcriptMatch ? '#00664A' : '#C51407'}
              startIcon={
                transcriptMatch ?
                <CheckCircleOutlinedIcon style={sx.success}/> :
                <ErrorOutlineOutlinedIcon style={sx.danger}/>
              }
            />
          </Box>
        </Box>
      }

      {
        analysed && micWorking && transcriptMatch ? 
          <Box className={classes.proceedBtn}>
            <Typography fontSize='12px' color='#fff'>You can proceed for the attempt</Typography>
            <Box style={{ cursor: 'pointer'}} onClick={onClose}>
              <EastIcon style={{ width: '18px', height: '18px', color: '#fff'}}/>
            </Box>
          </Box>
          : 
          <Suggestions
            header={ !analysed ? 'Suggestions' : 'How to fix'}
            data={
              analysed && micWorking && !transcriptMatch 
              ? troubleshootTranscriptError
              : analysed && !micWorking && !transcriptMatch
                ? troubleshootMicError
                : troubleshootSuggestions
            }
          />
      }
      {
        openSystemSettings &&
        <SystemSettingErrorState
          onClose={() => setOpenSystemSettings(false)}
        />
      }
      {
        openBrowserSettings &&
        <BrowserErrorState
          onClose={() => setOpenBrowserSettings(false)}
        />
      }
    </Box>
  )
}

const Suggestions = ({ data, header='Suggestions'}) => {
  const classes = useStyles();

  return (
    <Box className={classes.suggestionBox}>
      <Typography fontSize='14px' fontWeight={600}>{header}:</Typography>
      <Typography className={classes.suggestionItems} component="ul" sx={{ pl: 2, mb: 2 }}>
      {
        data.map((item, i) => (
          <li key={i}>{item}</li>
        ))
      }
      </Typography>
    </Box>
  )
}

const TroubleShoot = ({ onClose=()=>{}, openBrowserSettings, setOpenBrowserSettings }) => {
  const classes = useStyles();
  const [selected, setSelected] = React.useState(1);

  return (
    <Box className={classes.root}>
      <Box className={classes.header} mt={1}>
        <Typography>
          Troubleshoot
        </Typography>
        <Box style={{ cursor: 'pointer'}} onClick={onClose}>
          <CloseIcon style={{ width: '18px', height: '18px'}}/>
        </Box>
      </Box>
      <Divider/>

      <Box mt={2}>
        <Tabs
          value={selected}
          onChange={(value) => setSelected(value)}
          options={[
            { _id: 1, label: 'Microphone', icon: KeyboardVoiceOutlinedIcon },
            // { _id: 2, label: 'Compatibility', icon: CheckCircleOutlineOutlinedIcon },
            { _id: 3, label: 'Help & Support', icon: InfoOutlinedIcon },
          ]}
        />
      </Box>

      {
        selected === 1 && <Microphone onClose={onClose}/>
      }
      {
        selected === 3 && <Questions 
          openBrowserSettings={openBrowserSettings}
          setOpenBrowserSettings={setOpenBrowserSettings}
        />
      }
    </Box>
  )
}

export default TroubleShoot;